import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { graphql, useStaticQuery } from 'gatsby';

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 420,
  },
}));

const Story = props => {
  const data = useStaticQuery(graphql`
    query {
      pages: allContentfulPages(filter: {id: {eq: "30b867b6-cbce-5205-8438-5440f9388dcf"}}) {
        edges {
          node {
            title
            body {
              body
            }
            bodyExtra {
              bodyExtra
            }
            subTitleOne
            subBodyOne {
              subBodyOne
            }
            subTitleTwo
            subBodyTwo {
              subBodyTwo
            }
            id
            imageOne{
              fluid(maxWidth:800 maxHeight:600){
                src
              }
            }
          }
        }
      }
    }
  `)
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      {data.pages.edges.map((edge) => {
        return (
          <Grid
            container
            justify="space-between"
            spacing={isMd ? 4 : 2}
            direction={isMd ? 'row' : 'column-reverse'}
          >
            <Grid
              item
              container
              alignItems="center"
              justify="flex-start"
              xs={12}
              md={6}
              data-aos={'fade-up'}
            >
              <div>
                <SectionHeader
                  title={edge.node.body.body}
                  subtitle={edge.node.bodyExtra.bodyExtra}
                  align="left"
                  disableGutter
                  subtitleProps={{
                    color: 'textPrimary',
                    variant: 'body1',
                  }}
                />
              </div>
            </Grid>
            <Grid
              item
              container
              justify={isMd ? 'flex-end' : 'flex-start'}
              alignItems="center"
              xs={12}
              md={6}
              data-aos={'fade-up'}
            >
              <Image
                src={edge.node.imageOne.fluid.src}
                alt="Vendor"
                className={classes.image}
              />
            </Grid>
          </Grid>
         )
        })}
    </div>
  );
};

Story.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Story;
