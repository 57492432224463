import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { graphql, useStaticQuery } from 'gatsby'

const WhoWeAre = props => {
  const data = useStaticQuery(graphql`
    query {
      pages: allContentfulPages(filter: {id: {eq: "30b867b6-cbce-5205-8438-5440f9388dcf"}}) {
        edges {
          node {
            title
            subTitleOne
            subBodyOne {
              subBodyOne
            }
            subTitleTwo
            subBodyTwo {
              subBodyTwo
            }
            id
            imageOne{
              fluid(maxWidth:500 maxHeight:400){
                src
              }
            }
          }
        }
      }
    }
  `)
  const { className, ...rest } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      {data.pages.edges.map((edge) => {
        return (
          <div>
            <Grid container spacing={isMd ? 4 : 2}>
              <Grid item xs={12} sm={6} data-aos="fade-up">
                <SectionHeader
                  title={edge.node.subTitleOne}
                  subtitle={edge.node.subBodyOne.subBodyOne}
                  disableGutter
                  align="left"
                  subtitleProps={{
                    variant: 'body1',
                    color: 'textPrimary',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} data-aos="fade-up">
                <SectionHeader
                  title={edge.node.subTitleTwo}
                  subtitle={edge.node.subBodyTwo.subBodyTwo}
                  disableGutter
                  align="left"
                  subtitleProps={{
                    variant: 'body1',
                    color: 'textPrimary',
                  }}
                />
              </Grid>
            </Grid>
          </div>
        )
      })}
      
    </div>
  );
};

WhoWeAre.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default WhoWeAre;
